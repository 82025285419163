import { Inject, inject, Injectable } from '@angular/core';
import { Directionality } from '@angular/cdk/bidi';
import { DOCUMENT } from '@angular/common';
import { environment } from '@root/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageStoreService {
  currentLanguage!: string;

  private readonly directionality = inject(Directionality);
  private translateService = inject(TranslateService);

  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  initTranslation() {
    this.directionality.change.subscribe((value) => {
      this.document.dir = value;
      this.document.body.dir = value;

      (
        this.directionality as {
          value: string;
        }
      )['value'] = value;
    });

    const lang = this._getDefaultOrUserPreferenceLanguage();
    this.currentLanguage = lang;
    this.translateService.setDefaultLang(lang);
    this.document.documentElement.lang = this.currentLanguage;
    this.directionality.change.emit(
      this.currentLanguage === 'ar' ? 'rtl' : 'ltr'
    );
  }

  /**
   * Change language and direction
   * @param language : string
   */
  changeLanguage(language: string) {
    this.directionality.change.emit(language === 'ar' ? 'rtl' : 'ltr');
    this.translateService.use(language);
    this.currentLanguage = language;
    this.document.documentElement.lang = language;
    localStorage.setItem('language', language);
    window.location.reload();
  }

  private _getDefaultOrUserPreferenceLanguage() {
    return localStorage.getItem('language') || environment.default_lang;
  }
}
